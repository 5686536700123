import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {NgbDropdown, NgbDropdownModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {RouterModule} from '@angular/router';
import {FullComponent} from './layouts/full/full.component';
import {BlankComponent} from './layouts/blank/blank.component';
import { NavbarComponent } from './navbar/navbar.component';
import {AngularFireModule} from '@angular/fire';
import {environment} from '../environments/environment';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFireStorageModule} from '@angular/fire/storage';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {AuthModule} from './auth/auth.module';
import {DatePipe} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {UsersModule} from './users/users.module';

import { FooterComponent } from './layouts/footer/footer.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AgmCoreModule} from '@agm/core';
import {NgProgressModule} from 'ngx-progressbar';
import {NgxSpinnerModule} from "ngx-spinner";
import { ModalComponent } from './event-form/modal/modal/modal.component';

import localeEsMX from '@angular/common/locales/es-MX';
import { registerLocaleData } from '@angular/common';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {LanguageService}  from './services/langaje.service';
import { ContactusComponent } from './contactus/contactus.component';
import { MaterialModule } from './shared/material.module';
import { SupportComponent } from './support/support.component';

registerLocaleData(localeEsMX);

// Función de inicialización del traductor
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    BlankComponent,
    NavbarComponent,
    FooterComponent,
    ModalComponent,
    ContactusComponent,
    SupportComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    RouterModule,
    AppRoutingModule,
    NgbModule,
    NgbDropdownModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFirestoreModule,
    FormsModule, ReactiveFormsModule,
    HttpClientModule,
    AgmCoreModule.forRoot({apiKey: 'AIzaSyBWnCaMpfh-N9Xq1Rv56BqU-eAFdjqPbO8'}),
    NgProgressModule,
    NgxSpinnerModule,
    MaterialModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    DatePipe, 
    NgbDropdown,
    { provide: LOCALE_ID, useValue: 'es_MX' }
  ],
  exports: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
