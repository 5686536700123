<footer>
  <div class="footer-nav">
    <div class="footer_container">
      <div class="footer-left">
        <img src="assets/images/logo/tixygologoblanco.png" alt="" style="width: 100%;">
      </div>
      <div class="footer-right">
        <div class="footer-top">
          <div class="footer-aid">
            <div>
              <h2 class="nav-title">{{ "AID" | translate }}</h2>
            </div>
            <!-- <div>
              <a (click)="goToPrivacy()" class="footer-nav-link">{{ "Help Center" | translate }}</a>
            </div> -->
            <div>
              <a (click)="goToContact()" class="footer-nav-link">Soporte</a>
            </div>
            <div>
              <a (click)="goToPrivacyTab('Términos y condiciones')" class="footer-nav-link">{{ "Terms and Conditions" | translate }}</a>
            </div>
            <div>
              <a (click)="goToPrivacyTab('Políticas de privacidad')" class="footer-nav-link">Aviso de Privacidad</a>
            </div>
          </div>
       <div class="footer-categories">
            <div>
              <h2 class="nav-title">{{ "categories" | translate }}</h2>
            </div>
            <ng-container *ngFor="let category of categorizedEvents">
              <li *ngIf="hasValidEvents(category)">
                <a *ngIf="!currentLang" (click)="selectCategory(category.category)" class="footer-nav-link">{{ category.category.name }}</a>
                <a *ngIf="currentLang == 'en'" (click)="selectCategory(category.category)" class="footer-nav-link">{{ category.category.name_en }}</a>
                <a *ngIf="currentLang == 'es'" (click)="selectCategory(category.category)" class="footer-nav-link">{{ category.category.name }}</a>
              </li>
            </ng-container>
          </div> 
        </div>
        <div class="footer-bottom">
          <div class="footer-apps">
            <!-- <div>
              <p>{{ "Download our Aplications" | translate }}</p>
            </div>
            <div>
              <a href="https://apps.apple.com/mx/app/tixygo/id6473641167" target="_blank" class="footer-nav-link">
                <img src="/assets/home/app_store.png" alt="App Store">
              </a>
              <a href="https://play.google.com/store/apps/details?id=mx.qmarketing.TixygoApp" target="_blank" class="footer-nav-link">
                <img src="/assets/home/google_play.png" alt="Play Store">
              </a>
            </div> -->
          </div>
          <div class="footer-social">
            <div>
              <p>{{ "Let's Connect" | translate }}</p>
            </div>
            <div>
              <div class="icon-row">
                <a href="https://www.facebook.com/tixygomx"><img src="assets/images/logo/facebook.svg" alt=""></a>
                <a href="https://twitter.com/tixygomx"><img src="assets/images/logo/x.svg" alt=""></a>
                <a href="https://instagram.com/tixygomx?igshid=MzRlODBiNWFlZA=="><img src="assets/images/logo/instagram.svg" alt=""></a>
                <a href="https://www.tiktok.com/@tixygomx?_t=8gmjnCOeFPx&_r=1"><img src="assets/images/logo/tiktok.svg" alt=""></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-cards">
      <div class="payment-methods">
        <img src="./assets/images/credit/Visa.png" alt="visa" class="payment-img">
        <img src="./assets/images/credit/MasterCard.png" alt="Mastercard" class="payment-img">
        <img src="./assets/images/credit/AmericanExpress.png" alt="American Express" class="payment-img">
      </div>
      <div>
        <p class="footer_copyright">
          Copyright &copy; {{ "Tixigo Copyright © Tixygo All Rights Reserved." | translate }}
        </p>
      </div>
  </div>
</footer>
