import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import {AngularFireStorage} from '@angular/fire/storage';
import {Category} from '../categories/model/category';
import firebase from 'firebase/app';
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {
  private url = 'categories';

  constructor(private firestore: AngularFirestore,
              private storage: AngularFireStorage) { }

  /**
   * Observable de categorías
   */
  getCategories(): Observable<any[]>{
    return this.firestore.collection(this.url).valueChanges();
  }
  getCategoriesOnce(): Observable<any[]>{
    return this.firestore.collection(this.url).get().pipe(
      map(querySnapshot => {
        return querySnapshot.docs.map(doc => doc.data());
      })
    );
  }
  getCategoryOnce(categoryId: string): Observable<any> {
    return this.firestore.collection(this.url).doc(categoryId).get();
  }


  /**
   * Observable de subcategorías
   */
  getSubcategories(categoryId: string): Observable<any[]>{
    return this.firestore.collection(this.url).doc(categoryId)
      .collection('subcategories').valueChanges();
  }

  /**
   *
   * Guardar un archivo en Storage de Firebase
   *
   */
  async saveImage( id: string, file: File){
    const path = `categories/${id}`;
    const item = await this.storage.upload( path , file );
    return item.ref.getDownloadURL().then(res => {
      if (res){
        return {
          code : 200,
          estado: true,
          file : false,
          fileName : file.name,
          msg : 'Success',
          src : res
        };
      }
    });
  }

  /**
   *
   * Eliminar un archivo de storage
   *
   */
  async deleteImage( id: string): Promise<void>{
    const path = `categories/${id}`;
    return this.storage.storage.ref(path).delete()
      .then(() => {
        console.log('File deleted successfully');
      })
      .catch((error) => {
        console.log('Error deleting file:', error);
      });
  }

  /**
   *
   * Eliminar una Categoría
   */
  deleteCategory(categoryId: string, category: Category): Promise<void>{
    if (category.imageUrl.includes(category.id)){
      // 1. si la url trae el id, quiere decir que existe en Storage
      return this.deleteImage(category.id).then(() => {
        return this.firestore.collection(this.url).doc(categoryId)
          .delete();
      });
    } else {
      // 1.si la url NO trae el id, entonces borramos directo de la base de datos
      return this.firestore.collection(this.url).doc(categoryId)
        .delete();
    }
  }
  /**
   *
   * Eliminar una subcategoría
   */
  deleteSubcategory(categoryId: string, subcategory: Category): Promise<void>{
    if (subcategory.imageUrl.includes(subcategory.id)){
      // 1. si la url trae el id, quiere decir que existe en Storage
      return this.deleteImage(subcategory.id).then(() => {
        return this.firestore.collection(this.url).doc(categoryId)
          .collection('subcategories').doc(subcategory.id)
          .delete();
      });
    } else {
      // 1.si la url NO trae el id, entonces borramos directo de la base de datos
      return this.firestore.collection(this.url).doc(categoryId)
        .collection('subcategories').doc(subcategory.id)
        .delete();
    }
  }

  /**
   *
   * Guardar subcategoría en base de datos
   *
   */
  saveSubcategory(categoryId: string, subcategory: Category, file): Promise<void>{
   // 0. actualizar timestamps
    if (!subcategory.created_at) {
      subcategory.created_at = firebase.firestore.Timestamp.now();
    }
    subcategory.updated_at = firebase.firestore.Timestamp.now();

    // 1.si file existe, hay que subir imagen
    if (file){
      return this.saveImage(subcategory.id, file).then(result => {
        // 1.2 poner url en el model de la subcategoría
        subcategory.imageUrl = result.src;
        // 1.3 guardar en base de datos
        return this.firestore.collection(this.url).doc(categoryId)
          .collection('subcategories').doc(subcategory.id)
          .set(subcategory);
      });
    } else {
      // 1.2 si file no existe, poner una imagen de placeholder
      if (!subcategory.imageUrl.includes(subcategory.id)) {
        // la subcategoría NO tiene una url por que el string no incluye el id
        subcategory.imageUrl = 'https://firebasestorage.googleapis.com/v0/b/boletera-app-c06d7.appspot.com/o/categories%2Fimgplaceholder1.jpeg?alt=media&token=f72b1373-b444-487a-aa31-9f43817c1a86';
      }
      // 1.3 guardar en base de datos
      return this.firestore.collection(this.url).doc(categoryId)
        .collection('subcategories').doc(subcategory.id)
        .set(subcategory);
    }
  }
  /**
   *
   * Guardar categoría en base de datos
   *
   */
  saveCategory(categoryId: string, category: Category, file): Promise<void>{
    // 0. actualizar timestamps
    if (!category.created_at){
      category.created_at = firebase.firestore.Timestamp.now();
    }
    category.updated_at = firebase.firestore.Timestamp.now();

    // 1.si file existe, hay que subir imagen
    if (file){
      return this.saveImage(category.id, file).then(result => {
        // 1.2 poner url en el model de la subcategoría
        category.imageUrl = result.src;
        // 1.3 guardar en base de datos
        return this.firestore.collection(this.url).doc(categoryId)
          .set(category);
      });
    } else {
      // 1.2 si file no existe, poner una imagen de placeholder
      if (!category.imageUrl.includes(category.id)) {
        // la subcategoría NO tiene una url por que el string no incluye el id
        category.imageUrl = 'https://firebasestorage.googleapis.com/v0/b/boletera-app-c06d7.appspot.com/o/categories%2Fimgplaceholder1.jpeg?alt=media&token=f72b1373-b444-487a-aa31-9f43817c1a86';
      }
      // 1.3 guardar en base de datos
      return this.firestore.collection(this.url).doc(categoryId)
        .set(category);
    }
  }
}
