import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {
  hideNavbar = false;
  public user;
  constructor() { }
}
