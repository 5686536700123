import { Component, OnInit } from '@angular/core';
import {EventsService} from "../../services/events-service";
import {Router} from "@angular/router";


@Component({
  selector: 'app-blank',
  templateUrl: './blank.component.html',
  styleUrls: ['./blank.component.scss']
})
export class BlankComponent implements OnInit {

  constructor(private eventsService: EventsService,
              private router: Router) { }

  ngOnInit(): void {
    if (!this.eventsService.array){
      this.router.navigate(['/home/main']);
    } else {
      console.log('NOT NULL', this.eventsService.array);
    }
  }

}
