import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class SeatsService {


  private exportSeatMapUrl = 'https://us-central1-boletera-app-c06d7.cloudfunctions.net/export_seatmap'; // Replace with your API endpoint



  constructor(private http: HttpClient) {
  }

  /**
   * Bloquear asientos cuando un usuario está seleccionando
   */
  blockSeats(data: any){
   // const secretApiKey = 'sk_60cc05475e9b4fb28a4a7ba4ac634d7c';
   // const authn = 'Basic ' + btoa(secretApiKey + ':');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    // const options = {headers: headers};
    return this.http.post(environment.firebaseUrl + '/block_seats_user', data, httpOptions);
  }

  getSeatmap(){
    // const secretApiKey = 'sk_60cc05475e9b4fb28a4a7ba4ac634d7c';
    // const authn = 'Basic ' + btoa(secretApiKey + ':');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    // const options = {headers: headers};
    return this.http.post(this.exportSeatMapUrl, {}, httpOptions);
  }

}
