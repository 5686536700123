<div class="form-container">
  <form [formGroup]="contactForm" (ngSubmit)="onSubmit()" class="contact-form">
    <h2 class="text-title"><span>Vende</span> tu evento con nosotros</h2>
    <h6 class="text-title">Promociona y vende tus eventos con nosotros: Llena el formulario para comenzar</h6>
    <mat-divider class="divider"></mat-divider>

    <mat-form-field class="form-control" appearance="outlined">
      <mat-label>Nombre Completo</mat-label>
      <input matInput formControlName="fullName">
      <div *ngIf="submitted && f.fullName.errors">
        <div *ngIf="f.fullName.errors.required">El nombre es obligatorio.</div>
        <div *ngIf="f.fullName.errors.minlength">El nombre debe tener al menos 3 caracteres.</div>
      </div>
    </mat-form-field>

    <mat-form-field class="form-control" appearance="outlined">
      <mat-label>Correo Electrónico</mat-label>
      <input matInput type="email" formControlName="email">
      <div *ngIf="submitted && f.email.errors">
      <mat-error *ngIf="f.email.errors?.required">El correo electrónico es obligatorio.</mat-error>
      <mat-error *ngIf="f.email.errors?.email">Ingrese un correo electrónico válido.</mat-error>
    </div>
    </mat-form-field>

    <mat-form-field class="form-control" appearance="outlined">
      <mat-label>Número de Teléfono</mat-label>
      <input matInput type="tel" formControlName="phone">
      <div *ngIf="submitted && f.phone.errors">
      <mat-error *ngIf="f.phone.errors?.required">El número de teléfono es obligatorio.</mat-error>
      <mat-error *ngIf="f.phone.errors?.pattern">Ingrese un número de teléfono válido.</mat-error>
    </div>
    </mat-form-field>

    <mat-form-field class="form-control" appearance="outlined">
      <mat-label>Nombre del Evento</mat-label>
      <input matInput formControlName="eventName">
      <div *ngIf="submitted && f.eventName.errors">
         <mat-error *ngIf="submitted && f.eventName.errors?.required">El nombre del evento es obligatorio.</mat-error>
      </div>
     
    </mat-form-field>

    <mat-form-field class="form-control" appearance="outlined">
      <mat-label>Fecha del evento</mat-label>
      <input matInput [matDatepicker]="picker" formControlName="eventDate">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <div *ngIf="submitted && f.eventDate.errors">
      <mat-error *ngIf="submitted && f.eventDate.errors?.required">La fecha es obligatoria</mat-error>
      </div>  
    </mat-form-field>
    

    <mat-form-field class="form-control" appearance="outlined">
      <mat-label>Lugar del Evento</mat-label>
      <input matInput formControlName="eventLocation">
      <div *ngIf="submitted && f.eventLocation.errors">
      <mat-error *ngIf="submitted && f.eventLocation.errors?.required">El nombre del evento es obligatorio.</mat-error>
    </div>
    </mat-form-field>

    <mat-form-field class="form-control" appearance="outlined">
      <mat-label>Costo promedio por boleto</mat-label>
      <input matInput type="number" formControlName="ticketCost">
      <span matPrefix>$&nbsp;</span>
      <div *ngIf="submitted && f.eventLocation.errors">
      <mat-error *ngIf="submitted && f.ticketCost.errors?.required">Este campo es obligatorio.</mat-error>
      </div>
    </mat-form-field>

    <mat-form-field class="form-control" appearance="outlined">
      <mat-label>Aforo estimado</mat-label>
      <input matInput type="number" formControlName="estimatedAttendance">
      <div *ngIf="submitted && f.estimatedAttendance.errors">
      <mat-error *ngIf="submitted && f.estimatedAttendance.errors?.required">Este campo es obligatorio.</mat-error>
    </div>
    </mat-form-field>

    <mat-form-field class="form-control" appearance="outlined">
      <mat-label>Descripción del evento</mat-label>
      <textarea matInput formControlName="message"></textarea>
      <div *ngIf="submitted && f.message.errors">
      <mat-error *ngIf="f.message.errors?.required">El mensaje es obligatorio.</mat-error>
      <mat-error *ngIf="f.message.errors?.minlength">El mensaje debe tener al menos 10 caracteres.</mat-error>
      </div>
    </mat-form-field>

    <button mat-raised-button color="primary" type="submit" *ngIf="!isLoading; else loading" >
      <ng-container >
        Enviar
      </ng-container>
    </button>
    <ng-template #loading>
      <mat-progress-spinner 
        diameter="25" 
        [strokeWidth]="4" 
        mode="indeterminate">
      </mat-progress-spinner>
    </ng-template>
    
  </form>
</div>
