import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import {AppUsersService} from "./app-users.service";

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  idiomaIngles: string;
  idiomaES: string;
  private currentLanguageSubject: BehaviorSubject<string>;

  constructor(private translateService: TranslateService,
              private usersService: AppUsersService) {
    this.currentLanguageSubject = new BehaviorSubject<string>(this.translateService.currentLang);
    this.setAppLang();
  }

  setAppLang(): void {
    this.translateService.setDefaultLang('es');
    // this.translateService.use(this.translateService.getBrowserLang()!);
  }

  changeToEnglish(uid) {
    this.idiomaIngles = 'en';
    this.translateService.use(this.idiomaIngles);
    this.currentLanguageSubject.next(this.idiomaIngles);
    if (uid.length > 0){
      this.usersService.saveChanges({lang: this.idiomaIngles}, uid);
    }
  }

  changeToEsp(uid) {
    this.idiomaES = 'es';
    this.translateService.use(this.idiomaES);
    this.currentLanguageSubject.next(this.idiomaES);
    if (uid.length > 0){
      this.usersService.saveChanges({lang: this.idiomaES}, uid);
    }
  }

  getCurrentLanguage(): string {
    return this.translateService.currentLang;
  }

  getCurrentLanguageObservable() {
    return this.currentLanguageSubject.asObservable();
  }
}
