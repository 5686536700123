import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {CategoriesService} from "../../services/categories.service";
import {EventsService} from "../../services/events-service";
import {NgxSpinnerService} from "ngx-spinner";
import { Subscription } from 'rxjs';
import {LanguageService} from '../../services/langaje.service';
import { dateStringToDate } from 'tixygo-lib';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
 
  categories = [];
  events = [];
  categorizedEvents: any[] = [];
  currentLang: string;
  private languageSubscription: Subscription;
  constructor(private router: Router,
              private categoriesService: CategoriesService,
              private languageService: LanguageService,
              private eventsService: EventsService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {

        //servicio de idiomas
        this.currentLang = this.languageService.getCurrentLanguage();
        console.log("Current Language:", this.currentLang);
    
        // Suscripción al observable para detectar cambios de idioma
        this.languageSubscription = this.languageService
          .getCurrentLanguageObservable()
          .subscribe((newLang) => {
            this.currentLang = newLang;
            console.log("Current Language nav:", this.currentLang);
          });
        //end servicio de idiomas
    this.loadCategorizedEvents();
    this.categoriesService.getCategories().subscribe((categoryData) => {
      this.categories = categoryData;
      console.log("categories", this.categories);

      // Filtrar para mostrar solo las categorías de las que haya eventos existentes
      const eventCategoriesIds = new Set(
        this.events.map((event) => event.category)
      );
      this.categories = categoryData.filter((category) =>
        eventCategoriesIds.has(category.id)
      );
    });
  }
  loadCategorizedEvents(): void {
    this.categoriesService.getCategories().subscribe((categories) => {
      this.eventsService.getEvents().subscribe((events) => {
        this.categorizedEvents = this.mapEventsToCategories(categories, events);
        this.logCategorizedEvents();
      });
    });
  }

  hasValidEvents(category: any): boolean {
    return category.events.some(
      (event) => event.dates.length > 0 && event.post
    );
  }

  mapEventsToCategories(categories: any[], events: any[]): any[] {
    return categories.map((category) => {
      const categoryEvents = events.filter(
        (event) => event.category === category.id
      );
      const categorizedEvents = {
        category: category,
        events: categoryEvents.map((event) => {
          return {
            ...event,
            dates: this.removeExpiredDatesFromEvent(event),
          };
        }),
      };
      return categorizedEvents;
    });
  }

  removeExpiredDatesFromEvent(event) {
    const currentDate = new Date();
    var validDates = [];
    var validQueryDates = [];
    event.dates.forEach((date) => {
      const entranceDate = dateStringToDate(date.date);
      const entranceTime = dateStringToDate(date.purchaseLimitDate);
      entranceDate.setHours(entranceTime.getHours());
      entranceDate.setMinutes(entranceTime.getMinutes());
      if (entranceDate > currentDate) {
        if (date.post) {
          validDates.push(date);
          validQueryDates.push(date.startTime);
        }
      }
    });
    // event.queryDates = validQueryDates;
    return validDates;
  }

  logCategorizedEvents(): void {
    console.log("Categorized Events:");
    this.categorizedEvents.forEach((category) => {
      console.log("Category:", category.category.name);
      category.events.forEach((event) => {
        if (event.dates.length > 0 && event.post) {
          console.log("Event:", event.name);
          console.log("Dates:");
          event.dates.forEach((date) => {
            console.log(date);
          });
        }
      });
    });
  }

  goToPrivacyTab(tab: string): void {
    this.router.navigate(['/home/privacy'], { fragment: tab });
  }
  goToContact(): void {
    this.router.navigate(['/support']);
  }

  selectCategory(cat){
    this.spinner.show('mySpinner');
    setTimeout(() => {
      this.router.navigate(['/search']).then(() => {
        setTimeout(() => {
          this.eventsService.categorySelectedData.data = {
            isCategory: true,
            category: cat
          };
          this.spinner.hide('mySpinner');
          this.router.navigate(['events/events-category']);
        }, 200);
      });
    }, 1000);
  }

  selectSubcategory(sub){
    this.spinner.show('mySpinner');
    setTimeout(() => {
      this.router.navigate(['/search']).then(() => {
        setTimeout(() => {
          this.eventsService.categorySelectedData.data = {
            isCategory: false,
            subcategory: sub
          };
          this.spinner.hide('mySpinner');
          this.router.navigate(['events/events-category']);
        }, 200);
      });
    }, 1000);
  }


}
