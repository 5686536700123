import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {BlankComponent} from './layouts/blank/blank.component';
import {FullComponent} from './layouts/full/full.component';
import {AuthGuardService} from './services/auth-guard.service';
import { UserProfileComponent } from './user-profile/profile/user-profile/user-profile.component';
import { PaymentFormComponent } from './payments/payment-form/payment-form.component';
import { ContactusComponent } from './contactus/contactus.component';
import { SupportComponent } from './support/support.component';


const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'home/main' },
  {
    path: '',
    component: FullComponent,
    children : [{
      path : 'sesion', loadChildren: () => import('./auth/auth.module').then( m => m.AuthModule )
    }]
  },
  {
    path: '',
    component: FullComponent,
    children : [{
      path : 'home', loadChildren: () => import('./home/home.module').then( m => m.HomeModule )
    }]
  },
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: 'contact-us',
        component: ContactusComponent
      }
    ]
  },
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: 'support',
        component: SupportComponent
      }
    ]
  },
  {
    path: '',
    component: FullComponent,
    children : [{
      path : 'categories', loadChildren: () => import('./categories/categories.module').then( m => m.CategoriesModule )
    }],
    canActivate: [AuthGuardService]
  },
  {
    path: '',
    component: FullComponent,
    children : [{
      path : 'events', loadChildren: () => import('./events/events.module').then( m => m.EventsModule )
    }]
  },
  {
    path: '',
    component: FullComponent,
    children : [{
      path : 'user', loadChildren: () => import('./user-profile/user-profile.module').then( m => m.UserProfileModule )
    }]
  },
  {
    path: '',
    component: FullComponent,
    children : [{
      path : 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then( m => m.DashboardModule )
    }]
  },
  {
    path: '',
    component: BlankComponent,
    children : [{
      path : 'mobile', loadChildren: () => import('./mobile/mobile.module').then( m => m.MobileModule )
    }]
  },
  {
    path: 'search',
    component: BlankComponent
  },
  { path: '**', redirectTo: 'home/main'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
