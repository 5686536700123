import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  isLoggedUser = false;

  constructor(
    private firestore: AngularFirestore,
  ) { }

  getConfigurations(): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.firestore.collection("configurations").doc("5MdeHFntjSUSkv922LIL").get().subscribe(doc => {
        if (doc.exists) {
          resolve(doc.data());
        } else {
          throw new Error("No such document!");
        }
      });
    }) 
  }
}
