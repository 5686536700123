import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {
  transform(value: number): string {
    // Check if the input is a valid number
    if (isNaN(value) || value === null) {
      return ''; // Return an empty string for invalid input
    }

    // Convert the number to a string and split it into parts
    const parts = value.toFixed(2).toString().split('.');

    // Add commas to the integer part of the number
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // Join the parts back together and return
    return parts.join('.');
  }
}
