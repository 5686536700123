import { Component, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import firebase from 'firebase/app';
import { Router } from "@angular/router";
import { SeatsService } from "./services/seats.service";
import Swal from "sweetalert2";
import GeoPoint = firebase.firestore.GeoPoint;
import { EventsService } from "./services/events-service";
import { PlacesService } from "./services/places.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'testing';
  isAndroid = false;
  isiOS = false;
  userAgent;

  constructor(private router: Router, private seatsService: SeatsService, private placesService: PlacesService) {
    const userAgent = window.navigator.userAgent.toLowerCase();
    this.userAgent = userAgent;
    this.isAndroid = userAgent.indexOf('android') > -1;
    this.isiOS = userAgent.indexOf('iphone') > -1 || userAgent.indexOf('ipad') > -1 || userAgent.indexOf('ipod') > -1;

  }

  ngOnInit(): void {
    //firebase.initializeApp(environment.firebaseConfig);
    if (this.isAndroid) {
      // console.log('isAndroid', this.isAndroid);
      // this.router.navigate(['/mobile/landing']);
    } else if (this.isiOS) {
      // console.log('isiOS', this.isiOS);
      // this.router.navigate(['/mobile/landing']);
    }

    // this.getSeatMapSections();

  }


  /**
   * test functions
   */

  getSeatMapSections() {
    this.seatsService.getSeatmap().subscribe(
      (response) => {
        console.log('SEATMAP Post request successful', response);
        // Handle the response here
        const c = new GeoPoint(22.150984, -101.026657);
        response['data'].forEach(section => {
          section.created_at = new Date();
          section.updated_at = new Date();
          section.seats.forEach(seat => {
            let coordinate = new GeoPoint(seat.coordinate._latitude, seat.coordinate._longitude);
            seat.coordinate = coordinate;
          });
          //console.log('section', section);
          this.placesService.saveZone(section, 'ZTaiQD80Xlvlm8zy6u5J');
          //section.created_at = new Date();
          //section.updated_at = new Date();
          //this.placesService.saveRow(section, 'ZTaiQD80Xlvlm8zy6u5J');
        });
        console.log('dataplace', response['dataplace']);
        //this.placesService.savePlace(response['dataplace'], null);
      },
      (error) => {
        console.error('Error:', error);
        // Handle errors here
      }
    );
  }



}
