// src/app/shared-tools/material.module.ts

import { LOCALE_ID, NgModule } from '@angular/core';
import {MatTabsModule} from '@angular/material/tabs';
import { SharedModule } from './shared.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
// Importa otros módulos de Angular Material

registerLocaleData(localeEs, 'es');

@NgModule({
  imports: [
    SharedModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDividerModule,
    MatIconModule,
    MatSelectModule,
    MatProgressSpinnerModule
    // Otros módulos de Angular Material
  ],
  exports: [
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDividerModule,
    MatIconModule,
    MatSelectModule,
    MatProgressSpinnerModule
    // Exporta los módulos de Angular Material
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es-ES' }, // Configura la localización
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' } // Configura la localización para el MatDatepicker
  ],
})
export class MaterialModule { }
