import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';


@Component({
  selector: 'app-full',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss']
})
export class FullComponent implements OnInit {

  constructor(
      private router: Router
  ) {
  }
  ngOnInit(): void {
    const route = this.router.url;
    console.log('ROUTE', route);
  }

}
