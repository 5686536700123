<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">

<!-- banner -->
<section id="home" class="home-banner" style="height: 70px;">
  <!-- Navigation start -->

  <header class="header is-sticky" id="miNavbar">

    <nav class="navbar navbar-expand-lg navbar-custom navbar-light "
      style="position: fixed; width: 100%; height: 70px;">
      <div class="container">

        <a class="navbar-brand  mr-auto " (click)="goToHome()"><img class="logo_tixy" src="./assets/logoTixygoColor.png"
            alt=""></a>

        <button class="navbar-toggler" type="button">
          <li class="submenu-category action-icon-nav">
            <img *ngIf="!currentLanguage" src="./assets/images/country/mexico.png" alt="Bandera de México"
              class="banderaimagen">
            <img *ngIf="currentLanguage == 'es'" src="./assets/images/country/mexico.png" alt="Bandera de México"
              class="banderaimagen">
            <img *ngIf="currentLanguage == 'en'" src="./assets/images/country/usa.png" alt="Bandera de México"
              class="banderaimagen">
            <a class="hover-icon">{{ "logo_pais" | translate }}</a>
          </li>
        </button>

          <ul class="navbar-nav">
            <li id="dropdown-language-mobile">
              <div class="dropdown-language-div">
                <img class="dropdown-language-img" src="./assets/images/i_lenguaje.png" id="languageDropdown">
                <span class="hover-icon">{{ currentLanguage === 'es' ? 'ES' : 'EN' }}</span>
              </div> 
              <div class="dropdown-language-menu" (click)="currentLanguage === 'en' ? changeToEsp() : changeToEnglish()" aria-labelledby="languageDropdown">
                <img class="dropdown-language-img" src="./assets/images/i_lenguaje.png" alt="Español">
                <span class="hover-icon">{{ currentLanguage === 'es' ? 'EN' : 'ES' }}</span>
              </div>
            </li>
          </ul>
         
        <button class="navbar-toggler" type="button" (click)="toggleSlider()">
          <li class="submenu-category action-icon-nav" *ngIf="!userLogged">
            <img src="./assets/images/usuario.png" alt="Bandera de México" class="banderaimagen">
          </li>
          <li class="submenu-category action-icon-nav" *ngIf="userLogged && currentUser">
            <!-- <a class="hover-icon" href="/user/profile">{{currentUser.first_name}}</a> -->
            <img src="./assets/images/usuario.png" alt="Bandera de México" class="banderaimagen">
          </li>
        </button>

        <button style="display: none;" class="action-btn" data-mobile-menu-open-btn>
          <ion-icon name="grid-outline"></ion-icon>
        </button>




        <div class="collapse navbar-collapse" id="navbarToggler">
          <ul class="navbar-nav">

          <!-- Cambio idioma -->
          <li class="dropdown-language">
            <div class="dropdown-language-div">
              <img class="dropdown-language-img" src="./assets/images/i_lenguaje.png" id="languageDropdown">
              <span class="hover-icon">{{ currentLanguage === 'es' ? 'ES' : 'EN' }}</span>
            </div> 
            <div class="dropdown-language-menu" (click)="currentLanguage === 'en' ? changeToEsp() : changeToEnglish()" aria-labelledby="languageDropdown">
              <img class="dropdown-language-img" src="./assets/images/i_lenguaje.png" alt="Español">
              <span class="hover-icon">{{ currentLanguage === 'es' ? 'EN' : 'ES' }}</span>
            </div>
          </li>

            <li class="submenu-category action-bandera" for="menu__toggle2" (click)="goToCountry()">
              <input id="menu__toggle2" type="checkbox" [(ngModel)]="menuToggleChecked2" style="display: none;" />
              <label for="menu__toggle2" (click)="toggleMenu2()">
                <img *ngIf="!currentLanguage" src="./assets/images/country/mexico.png" alt="Bandera de México"
                  class="banderaimagen" data-mobile-menu-open-btn>
                <img *ngIf="currentLanguage == 'es'" src="./assets/images/country/mexico.png" alt="Bandera de México"
                  class="banderaimagen" data-mobile-menu-open-btn>
                <img *ngIf="currentLanguage == 'en'" src="./assets/images/country/usa.png" alt="Bandera de México"
                  class="banderaimagen" data-mobile-menu-open-btn>
              </label>
              <a class="hover-icon">{{ "logo_pais" | translate }}</a>
            </li>

            <!-- segundo slide -->
            <div class="hamburger-menu">
              <input style="display: none;" id="menu__toggle2" type="checkbox" [(ngModel)]="menuToggleChecked2" />
              <label style="display: none;" class="menu__btn" for="menu__toggle2" (click)="toggleMenu2()">
                <span></span>
              </label>
              <ul class="menu__box">
                <button id="closeButton2" class="close__button">
                  <input id="menu__toggle2" type="checkbox" />
                  <label class="menu__btn" for="menu__toggle2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                      class="bi bi-x-lg" viewBox="0 0 16 16">
                      <path
                        d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                    </svg>
                  </label>
                </button>
                <li><a class="menu__item" (click)="changeToEsp()"> <img src="./assets/images/bandera.png"
                      alt="Bandera de España" class="banderaimagen">ES</a></li>
                <li><a class="menu__item" (click)="changeToEnglish()"><img src="./assets/images/usa.png"
                      alt="Bandera de Estados Unidos" class="banderaimagen">US</a></li>
                <!-- Resto de los elementos del menú -->
              </ul>
            </div>
            <!-- end segundo slide -->



            <li class="submenu-category action-bandera buttonLogin" *ngIf="!userLogged">
              <a (click)="goToLogin()" class="hover-icon">{{ "Iniciar Sesión" | translate }}</a>
            </li>

            <li class="submenu-category action-bandera " *ngIf="userLogged && currentUser" (click)="toggleSlider()">
              <div class="dropdown-user">
                <button class="dropdown-user-btn" aria-haspopup="menu">
                  <span class="name-user">{{currentUser.first_name}}</span>
                </button>
              </div>
              <img src="./assets/images/usuario.png" alt="Bandera de México" class="banderaimagen">
            </li>
          </ul>
        </div>
      </div>
    </nav>

  </header>

  <!-- Navigation end -->

</section>
<!-- end banner -->


<nav class="mobile-navigation-menu has-scrollbar" [class.active]="isSliderOpen">

  <div class="menu-top">
    <h2 class="menu-title">{{ "Menu" | translate }}</h2>

    <button class="menu-close-btn" (click)="toggleSlider()">
      <ion-icon name="close-outline"></ion-icon>
    </button>
  </div>

  <ul class="mobile-menu-category-list">

    <li class="menu-category">
      <a (click)="goToHome()" class="menu-title">Home</a>
    </li>

    <li class="menu-category">

      <button class="accordion-menu" (click)="toggleAccordion()">
        <p class="menu-title">{{ "account" | translate }}</p>

        <div>
          <ion-icon *ngIf="!isAccordionOpen" name="add-outline" class="add-icon"></ion-icon>
          <ion-icon *ngIf="isAccordionOpen" name="remove-outline" class="remove-icon"></ion-icon>
        </div>
      </button>

      <ul class="submenu-category-list" [class.active]="isAccordionOpen">

        <li *ngIf="userLogged" class="submenu-category">
          <a (click)="goToProfile()" class="submenu-title">Mi perfil</a>
        </li>

        <li *ngIf="userLogged" class="submenu-category">
          <a (click)="goToPurchases()" class="submenu-title">Mis boletos</a>
        </li>

        <!-- <li *ngIf="userLogged" class="submenu-category">
          <a (click)="goToPayments()" class="submenu-title">{{ "pay" | translate }}</a>
        </li> -->

        <li *ngIf="userLogged" class="submenu-category">
          <a (click)="goToSavedEvents()" class="submenu-title">{{ "saved_events" | translate }}</a>
        </li>

        <li *ngIf="!userLogged" class="submenu-category">
          <a (click)="toggleDropdown()" class="submenu-title">{{ "Log in" | translate }}</a>
        </li>

        <li *ngIf="userLogged" class="submenu-category" (click)="logout()">
          <a (click)="logout()" class="submenu-title">{{ "go_out" | translate }}</a>
        </li>
      </ul>
    </li>

  </ul>

  <div class="menu-bottom">

    <ul class="menu-category-list">

      <li class="menu-category">

        <button class="accordion-menu" (click)="toggleAccordionCountry()">
          <p class="menu-title">{{ "idiom" | translate }}</p>

          <div>
            <ion-icon *ngIf="!isAccordionOpen" name="add-outline" class="add-icon"></ion-icon>
            <ion-icon *ngIf="isAccordionOpen" name="remove-outline" class="remove-icon"></ion-icon>
          </div>
        </button>

        <ul class="submenu-category-list" [class.active]="isAccordionCountryOpen">

          <!-- <li *ngIf="userLogged" class="submenu-category">
                <a (click)="goToProfile()" class="submenu-title">Mi cuenta</a>
              </li> -->

          <li class="submenu-category action-bandera" (click)="changeToEsp()">
            <img src="./assets/images/country/mexico.png" alt="Bandera de México" class="banderaimagen">
            <a>ES</a>
          </li>
          <!-- <li class="submenu-category action-bandera">
            <img src="./assets/images/canada.png" alt="Bandera de México" class="banderaimagen">
            <a href="/sesion/inicio">Canada</a>
          </li> -->
          <li class="submenu-category action-bandera" (click)="changeToEnglish()">
            <img src="./assets/images/country/usa.png" style="width: 28px;" alt="Bandera de México" class="banderaimagen">
            <a>EN</a>
          </li>
        </ul>
      </li>

    </ul>

    <ul class="menu-social-container">

      <li>
        <a href="https://www.facebook.com/tixygomx" class="social-link">
          <ion-icon name="logo-facebook"></ion-icon>
        </a>
      </li>

      <li>
        <a href="https://twitter.com/tixygomx" class="social-link">
          <!--<ion-icon name="logo-twitter"></ion-icon>-->
          <img src="assets/images/logo/x-black.svg" style="height: 16px" alt="">
        </a>
      </li>

      <li>
        <a href="https://instagram.com/tixygomx?igshid=MzRlODBiNWFlZA==" class="social-link">
          <ion-icon name="logo-instagram"></ion-icon>
        </a>
      </li>

      <li>
        <a href="https://www.tiktok.com/@tixygomx?_t=8gmjnCOeFPx&_r=1" class="social-link">
          <ion-icon name="logo-tiktok"></ion-icon>
        </a>
      </li>

    </ul>

  </div>

</nav>

<ngx-spinner bdColor="rgb(0 0 0 / 79%)" name="mySpinner" template="<img src='assets/preloader_Tixygo.gif' style='height:150px; z-index:1000000; border-radius:10px'
/>"></ngx-spinner>



<!-- modal -->
<div class="modal" #myModal style="z-index: 999;">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{"Selecciona el rango de fechas" | translate}}</h5>
        <button type="button" class="close" (click)="closeModal()" style="position: absolute; top: 5px; right: 10px;">
          <span aria-hidden="true" style="font-size: 24px;">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="margin-left: 0px">
        <!-- Contenido del modal -->
        <div class="calendar-grande">
          <ngb-datepicker #dp (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t"
            outsideDays="hidden"></ngb-datepicker>
        </div>

        <div class="calendar-chico">
          <ngb-datepicker #dp (dateSelect)="onDateSelection($event)" [displayMonths]="1" [dayTemplate]="t"
            outsideDays="hidden" style="width: 100%;"></ngb-datepicker>
        </div>

        <ng-template #t let-date let-focused="focused">
          <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
            [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
            (mouseleave)="hoveredDate = null">
            {{ date.day }}
          </span>
        </ng-template>

      </div>
    </div>
  </div>
</div>
<!-- end modal -->