import { Component, OnInit } from '@angular/core';
import { EventsService } from '../services/events-service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {
  contactForm!: FormGroup;
  isLoading = false;
  submitted = false;


  constructor(private fb: FormBuilder, private eventsService: EventsService, private router: Router) { }

  ngOnInit(): void {
    this.contactForm = this.fb.group({
      fullName: ['', [Validators.required, Validators.minLength(3)]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, Validators.pattern('^\\+?\\d{10,15}$')]],
      message: ['', [Validators.required, Validators.minLength(10)]],
    });
  }

  get f() {
    return this.contactForm.controls;
  }

  onSubmit() {

    this.submitted = true;

    if (this.contactForm.invalid) {
      return;
    }

    console.log('Formulario enviado:', this.contactForm.value);

    this.isLoading = true;

    this.eventsService.sendContactMail(this.contactForm.value).then(async response => {
      console.log('Correo enviado con éxito:', response);

      this.isLoading = false;

      switch (response.code) {
        case "TK001":
          Swal.fire({
            title: "¡Proceso Exitoso!",
            text: "El ticket ha sido guardado y el email enviado correctamente.",
            icon: 'success',
            showConfirmButton: false,
            timer: 3000
          });
          break;
        case "TK002":
          Swal.fire({
            title: "Error en el Proceso!",
            text: "Ocurrió un error al guardar el ticket de soporte, intente nuevamente.",
            icon: 'error',
            confirmButtonText: "Aceptar"
          });
          break;
        case "TK003":
          Swal.fire({
            title: "Error de Envío de Email!",
            text: "Ocurrió un error al enviar el email a través del servicio de Resend, vuelva a intentar más tarde.",
            icon: 'error',
            confirmButtonText: "Aceptar"
          });
          break;
        case "TK004":
          Swal.fire({
            title: "Error en la Petición!",
            text: "Los campos ingresados son incorrectos, verifique nuevamente.",
            icon: 'error',
            confirmButtonText: "Aceptar"
          });
          break;
        default:
          Swal.fire({
            title: "¡Correo Enviado!",
            text: response.message || "El correo ha sido enviado exitosamente.",
            icon: 'success',
            showConfirmButton: false,
            timer: 3000
          });
          break;
      }

      this.router.navigate(['/home/main']);
      this.contactForm.reset(); 
      this.submitted = false;  

    }).catch((error: HttpErrorResponse) => {
      this.isLoading = false;
      if (error.status === 500) {
        Swal.fire({
          title: "Error en el servidor!",
          text: "Hubo un problema al procesar su solicitud. Por favor, inténtelo de nuevo más tarde.",
          icon: 'error',
          confirmButtonText: "Aceptar"
        });
      } else {
        Swal.fire({
          title: "Error!",
          text: error.message || "Ha ocurrido un error inesperado.",
          icon: 'error',
          confirmButtonText: "Aceptar"
        });
      }
    });
  }
}