export const environment = {
  production: true,
  // firebaseUrl: 'http://127.0.0.1:5001/boletera-app-c06d7/us-central1',
  firebaseUrl: 'https://us-central1-boletera-app-c06d7.cloudfunctions.net',
  openpayUrl: 'https://api.openpay.mx',
  secretKey: 'pk_881de37db0914eae89d15794b0bd46cc',
  customerId: 'muvfhhafpjbwb1tpphzt',
  publicKey: 'pk_881de37db0914eae89d15794b0bd46cc',
  redirectUri: 'https://tixygo.com.mx/user/profile',
  sandboxMode: false,
  firebaseConfig: {
    apiKey: 'AIzaSyA5Odj79SNJoP94Q1erGeJpLePseKR2BZQ',
    authDomain: 'boletera-app-c06d7.firebaseapp.com',
    projectId: 'boletera-app-c06d7',
    storageBucket: 'boletera-app-c06d7.appspot.com',
    messagingSenderId: '671629754733',
    appId: '1:671629754733:web:40bd99118f5a960cd1da64',
    measurementId: 'G-22RM53BGXV'
  },
  api_map: 'https://vmt-dev.ddns.net'
};
