import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import {AngularFireStorage} from '@angular/fire/storage';

@Injectable({
  providedIn: 'root'
})
export class PlacesService {

  private url = 'places';
  private urlZone = 'zones';
  constructor(private firestore: AngularFirestore,
              private storage: AngularFireStorage) { }

  /**
   * Observable de los documentos de colección de lugares.
   */
  getPlaces(): Observable<any[]>{
    return this.firestore.collection(this.url).valueChanges();
  }

  /**
   * Observable de los documentos de colección de lugares.
   */
  getPlace(placeId: string): Observable<any>{
    return this.firestore.collection(this.url).doc(placeId).valueChanges();
  }

  /**
   * Observable de los usuarios de colección de lugares.
   */
  getZones(placeId): Observable<any[]>{
    return this.firestore.collection(this.url).doc(placeId)
      .collection(this.urlZone).valueChanges();
  }

  /**
   * Guardar nuevo lugar en base de datos
   */
  savePlace(place: any, file: File): Promise<void>{
    // 1.3 guardar en base de datos
    if (file){
      return this.saveImage(place.id, file).then(result => {
        // 1.2 poner url en el model de la subcategoría
        place.blueprint = result.src;
        // 1.3 guardar en base de datos
        return this.firestore.collection(this.url).doc(place.id)
          .set(place);
      });
    } else {
      place.blueprint = '#';
      return this.firestore.collection(this.url).doc(place.id)
        .set(place);
    }
  }

  /**
   * Guardar una zona en base de datos
   */
  saveZone(zone, placeId): Promise<void>{
    // 1.3 guardar en base de datos
    return this.firestore.collection(this.url)
      .doc(placeId).collection(this.urlZone).doc(zone.id)
      .set(zone);
  }

  /**
   * Guardar una fila en base de datos
   */
  saveRow(row, placeId): Promise<void>{
    // 1.3 guardar en base de datos
    return this.firestore.collection(this.url)
        .doc(placeId).collection('rows').doc(row.id)
        .set(row);
  }


  /**
   *
   * Guardar un archivo en Storage de Firebase
   *
   */
  // tslint:disable-next-line:typedef
  async saveImage( id: string, file: File){
    const path = `places/${id}`;
    const item = await this.storage.upload( path , file );
    return item.ref.getDownloadURL().then(res => {
      if (res){
        return {
          code : 200,
          estado: true,
          file : false,
          fileName : file.name,
          msg : 'Success',
          src : res
        };
      }
    });
  }
}

