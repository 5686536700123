<div class="form-container">
    <form [formGroup]="contactForm" (ngSubmit)="onSubmit()" class="contact-form">
      <h2 class="text-title">Contáctanos</h2>
      <h6 class="text-title">Estamos aquí para ayudarte, déjanos un mensaje con tu requerimiento y en breve estaremos contigo</h6>
      <mat-divider class="divider"></mat-divider>
  
      <mat-form-field class="form-control" appearance="outlined">
        <mat-label>Nombre Completo</mat-label>
        <input matInput formControlName="fullName">
        <div *ngIf="submitted && f.fullName.errors">
          <div *ngIf="f.fullName.errors.required">El nombre es obligatorio.</div>
          <div *ngIf="f.fullName.errors.minlength">El nombre debe tener al menos 3 caracteres.</div>
        </div>
      </mat-form-field>
  
      <mat-form-field class="form-control" appearance="outlined">
        <mat-label>Correo Electrónico</mat-label>
        <input matInput type="email" formControlName="email">
        <div *ngIf="submitted && f.email.errors">
        <mat-error *ngIf="f.email.errors?.required">El correo electrónico es obligatorio.</mat-error>
        <mat-error *ngIf="f.email.errors?.email">Ingrese un correo electrónico válido.</mat-error>
      </div>
      </mat-form-field>
  
      <mat-form-field class="form-control" appearance="outlined">
        <mat-label>Número de Teléfono</mat-label>
        <input matInput type="tel" formControlName="phone">
        <div *ngIf="submitted && f.phone.errors">
        <mat-error *ngIf="f.phone.errors?.required">El número de teléfono es obligatorio.</mat-error>
        <mat-error *ngIf="f.phone.errors?.pattern">Ingrese un número de teléfono válido.</mat-error>
      </div>
      </mat-form-field>
  
      <mat-form-field class="form-control" appearance="outlined">
        <mat-label>Mensaje</mat-label>
        <textarea matInput formControlName="message"></textarea>
        <div *ngIf="submitted && f.message.errors">
        <mat-error *ngIf="f.message.errors?.required">El mensaje es obligatorio.</mat-error>
        <mat-error *ngIf="f.message.errors?.minlength">El mensaje debe tener al menos 10 caracteres.</mat-error>
        </div>
      </mat-form-field>
  
      <button mat-raised-button color="primary" type="submit" *ngIf="!isLoading; else loading" >
        <ng-container >
          Enviar
        </ng-container>
      </button>
      <ng-template #loading>
        <mat-progress-spinner 
          diameter="25" 
          [strokeWidth]="4" 
          mode="indeterminate">
        </mat-progress-spinner>
      </ng-template>
      
    </form>
  </div>
  